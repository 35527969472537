import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;

// 查询工艺质量列表
export function listProductQuality(query: any) {
  return request({
    url: "/factory/productQuality/list",
    method: "get",
    params: query,
  });
}

// 查询工艺质量详细
export function getProductQuality(query: any) {
  return request({
    url: "/factory/productQuality/getInfo",
    method: "get",
    params: query,
  });
}

// 新增工艺质量
export function addProductQuality(data: any) {
  return request({
    url: "/factory/productQuality",
    method: "post",
    data: data,
  });
}

// 修改工艺质量
export function updateProductQuality(data: any) {
  return request({
    url: "/factory/productQuality",
    method: "put",
    data: data,
  });
}

// 删除工艺质量
export function delProductQuality(data: any) {
  return request({
    url: "/factory/productQuality/removes",
    method: "delete",
    data: data,
  });
}
