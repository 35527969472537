import actions from "@/qiankun/actions";

let data = <any>actions.actions;
let request = data.request;

// 查询生产计划列表
export function listProductPlan(query: any) {
  return request({
    url: "/factory/productPlan/list",
    method: "get",
    params: query,
  });
}

// 查询生产计划详细
export function getProductPlan(query: any) {
  return request({
    url: "/factory/productPlan/getInfo",
    method: "get",
    params: query,
  });
}

// 新增生产计划
export function addProductPlan(data: any) {
  return request({
    url: "/factory/productPlan",
    method: "post",
    data: data,
  });
}

// 修改生产计划
export function updateProductPlan(data: any) {
  return request({
    url: "/factory/productPlan",
    method: "put",
    data: data,
  });
}

// 删除生产计划
export function delProductPlan(data: any) {
  return request({
    url: "/factory/productPlan/removes",
    method: "delete",
    data: data,
  });
}

// 切换状态
export function checkStatus(data: any) {
  return request({
    url: "/factory/productPlan/updateStatus",
    method: "PUT",
    data: data,
  });
}

// 切换点检状态
export function spotCheck(data: any) {
  return request({
    url: "/factory/productPlan/spotCheck",
    method: "PUT",
    data: data,
  });
}

// 下拉框列表
export function selectProPlan(query: any) {
  return request({
    url: "/factory/productPlan/selectList",
    method: "get",
    params: query,
  });
}

// 批量修改
export function batchModify(data: any) {
  return request({
    url: "/factory/productQuality/batchEdit",
    method: "PUT",
    data: data,
  });
}
